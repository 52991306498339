var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-container" }, [
    _c(
      "form",
      {
        staticClass: "form-group",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitNewPass.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "b-input-group",
          [
            _c("b-form-input", {
              staticClass: "inputText",
              class: { "is-invalid": _vm.$v.user.newPassword.$error },
              attrs: {
                id: "pass-input",
                type: _vm.newPasswordFieldType,
                placeholder: "Digite sua senha",
              },
              on: {
                focus: function ($event) {
                  return _vm.onFocusChange()
                },
              },
              model: {
                value: _vm.user.newPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "newPassword", $$v)
                },
                expression: "user.newPassword",
              },
            }),
            _c(
              "b-input-group-append",
              [
                _c(
                  "b-input-group-text",
                  [
                    _c("b-icon", {
                      attrs: { icon: _vm.changeIcon("newPassword") },
                      on: {
                        click: function ($event) {
                          return _vm.switchVisibility("newPassword")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$v.user.newPassword.$error,
                    expression: "$v.user.newPassword.$error",
                  },
                ],
                staticClass: "invalid-feedback",
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$v.user.newPassword.required,
                        expression: "!$v.user.newPassword.required",
                      },
                    ],
                  },
                  [_vm._v("Senha é obrigatória."), _c("br")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$v.user.newPassword.minLength,
                        expression: "!$v.user.newPassword.minLength",
                      },
                    ],
                  },
                  [_vm._v("Senha deve ter no mínimo 8 caracteres."), _c("br")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.passwordUppercase,
                        expression: "!passwordUppercase",
                      },
                    ],
                  },
                  [_vm._v(" Senha deve ter uma letra maiúscula."), _c("br")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.passwordLowercase,
                        expression: "!passwordLowercase",
                      },
                    ],
                  },
                  [_vm._v("Pelo menos um caractere minúsculo."), _c("br")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.passwordSpecial,
                        expression: "!passwordSpecial",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "Pelo menos um caractere especial (!@#()-[{}]:;',?/*~$^+=.`|%). "
                    ),
                    _c("br"),
                  ]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.user.newPassword && !_vm.passwordNumber,
                        expression: "user.newPassword && !passwordNumber",
                      },
                    ],
                  },
                  [_vm._v("Senha deve contar ao menos um número. "), _c("br")]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "b-input-group",
          { staticStyle: { "margin-top": "15px" } },
          [
            _c("b-form-input", {
              staticClass: "inputText",
              class: {
                "is-invalid": _vm.$v.user.confirmNewPassword.$error,
              },
              attrs: {
                id: "confirmNewPassword",
                type: _vm.confirmNewPasswordFieldType,
                name: "confirmNewPassword",
                placeholder: "Confirme sua senha",
              },
              model: {
                value: _vm.user.confirmNewPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "confirmNewPassword", $$v)
                },
                expression: "user.confirmNewPassword",
              },
            }),
            _c(
              "b-input-group-append",
              [
                _c(
                  "b-input-group-text",
                  [
                    _c("b-icon", {
                      attrs: { icon: _vm.changeIcon("confirmNewPassword") },
                      on: {
                        click: function ($event) {
                          return _vm.switchVisibility("confirmNewPassword")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.$v.user.confirmNewPassword.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  !_vm.$v.user.confirmNewPassword.required
                    ? _c("span", [
                        _vm._v("Confirmação de senha é obrigatória."),
                      ])
                    : !_vm.$v.user.confirmNewPassword.sameAsPassword
                    ? _c("span", [_vm._v("Senhas devem ser iguais")])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "submit-button" },
          [
            _c(
              "b-button",
              { staticClass: "new-monitor-button", attrs: { type: "submit" } },
              [_vm._v("Alterar")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTextVersion,
            expression: "showTextVersion",
          },
        ],
        staticClass: "text-version",
      },
      [_vm._v(" Ver.: " + _vm._s(_vm.version()) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }